import { render, staticRenderFns } from "./composite-item-lot-quantity.vue?vue&type=template&id=a8f5a27c&scoped=true&"
import script from "./composite-item-lot-quantity.vue?vue&type=script&lang=js&"
export * from "./composite-item-lot-quantity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8f5a27c",
  null
  
)

export default component.exports