<template>
  <b-table
    :id="id"
    hover
    small
    striped
    responsive
    empty-text="Item sem Hisorico"
    empty-filtered-text="Não foi encontrada nenhum historico com este filtro"
    :show-empty="true"
    :items="getPage"
    :fields="fields"
    :apiUrl="url"
    :current-page="currentPage"
    :per-page="perPage"
  >

    <div slot="table-busy" class="text-center text-danger my-2">
      <animation />
    </div>

    <template slot="allocationUsed" slot-scope="row">
      {{ row.item.allocationUsed.name }}
    </template>

    <template slot="document" slot-scope="row">
      {{ row.item.fromOrder.document }}
    </template>

    <template slot="operation" slot-scope="row">
      {{ row.item.fromOrder.operation }}
    </template>

    <template slot="person" slot-scope="row">
      {{ row.item.fromOrder.person }}
    </template>

    <template slot="user" slot-scope="row">
      {{ row.item.taskUser.userName }}
    </template>

    <template slot="date" slot-scope="row">
      {{ row.item.taskUser.start }}
    </template>

  </b-table>
</template>

<script>
  import { httpClient } from '@/service'
  import Animation from '@/components/loaders/animation'

  export default {
    name: 'table-history-item',
    components: { Animation },
    props: {
      id: {
        type: String,
        require
      },
      fields: {
        type: [Array, Object],
        default: () => [],
      },
      url: {
        type: String,
      },
      resourceName: {
        type: String,
      },
    },

    data: () => {
      return {
        currentPage: 1,
        perPage: 20,
        totalRows: 20,
      }
    },

    methods: {
      getPage (context) {
        const sortBy = context.sortBy ? `&sort=${context.sortBy},${context.sortDesc ? 'desc' : 'asc'}` : ''

        return httpClient
        .get(`${context.apiUrl}?page=${context.currentPage - 1}&size=${context.perPage}${sortBy}`)
        .then(({ data }) => {
          this.totalRows = data.page.totalElements
          const items = data._embedded[this.resourceName]
          return (items)
        }).catch(() => [])
      },
    },
  }
</script>

<style scoped>

</style>
