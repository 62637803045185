<template>
  <b-modal
    size="sm"
    centered
    lazy
    :title="title"
    header-bg-variant="info"
    footer-border-variant="info"
    v-model="show"
    @hidden="onHidden"
    :hide-footer="true"
  >
    <b-container>

      <div v-if="saving" class="row justify-content-center">
        <div class="col-auto">
          <animation />
        </div>
      </div>

      <div v-else>
        <b-form-group
          label="Quantidade por Volume"
          label-for="input-quantityPerVolume"
        >
          <b-form-input
            id="input-quantityPerVolume"
            v-model="quantityPerVolume"
            :disabled="saving"
          />
        </b-form-group>

        <b-button variant="success"
          @click="save()"
          :disabled="saving"
          class="btn-block">
          Salvar
        </b-button>
      </div>

    </b-container>
  </b-modal>
</template>

<script>
import Animation from "@/components/loaders/animation";
import { httpClient } from "@/service";

export default {
  name: 'item-lot-update-modal',
  components: {
    Animation
  },

  props: {
    onHidden: {
      type: Function,
      default: () => {
        return;
      }
    },
    itemLot: {
      type: Object
    },
    saved: {
      type: Function
    }
  },

  data() {
    return {
      show: false,
      quantityPerVolume: null,
      saving: false
    }
  },

  computed: {
    title() {
      return 'Alterar Lote ' + (this.itemLot ? this.itemLot.lotNumber : '');
    }
  },

  methods: {
    showModal() {
      this.show = true;

      if (this.itemLot) {
        this.quantityPerVolume = this.itemLot.quantityPerVolume;
      }
    },

    hideModal() {
      this.show = false;
    },

    save() {
      this.saving = true;
      httpClient
      .patch(`${process.env.VUE_APP_API_URL}item-lots/${this.itemLot.id}`, {
        quantityPerVolume: this.quantityPerVolume
      }).then(data => {
        this.saving = false;
        this.$notify.success('Salvo com sucesso');

        if (this.saved) {
          this.saved({
            quantityPerVolume: this.quantityPerVolume
          });
        }

        this.hideModal();
      })
      .catch((error) => {
        this.saving = false;

        if (error.message) {
          this.$notify.error(error)
        }
      });
    }
  }
}
</script>
