<template>
  <span>
    <span v-if="itemLot && itemLot.quantityPerVolume">
      <span v-if="hasMovedNumberOfVolumes">{{movedNumberOfVolumes}} / </span>
      {{ numberOfVolumes }}
      <small>
        <span class="text-muted">x </span>
        {{ itemLot.quantityPerVolume }}
      </small>
      {{ volumesRestLabel }}
      =
    </span>
    <span :class="itemLot && itemLot.quantityPerVolume ? 'h5 font-weight-bold' : ''">
      <span v-if="hasMovedQuantity">{{movedQuantity}} / </span>
      {{ quantity || 0 }}
    </span>
  </span>
</template>

<script>
  export default {
    name: 'composite-item-lot-quantity',

    props: {
      quantity: {},
      movedQuantity: null,
      itemLot: {}
    },

    computed: {
      numberOfVolumes() {
        if (this.itemLot && this.itemLot.quantityPerVolume) {
          return Math.floor(Number(this.quantity || 0) / Number(this.itemLot.quantityPerVolume));
        }
      },

      volumesRestLabel() {
        if (this.itemLot && this.itemLot.quantityPerVolume) {
          let rest = Number(this.quantity || 0) % Number(this.itemLot.quantityPerVolume);

          if (rest) {
            return ' +' + rest;
          }
        }

        return '';
      },

      movedNumberOfVolumes() {
        if (this.hasMovedQuantity && this.itemLot && this.itemLot.quantityPerVolume) {
          return Math.floor(this.movedQuantity / this.itemLot.quantityPerVolume);
        }
      },

      hasMovedQuantity() {
        return this.movedQuantity !== undefined && this.movedQuantity !== null && !isNaN(this.movedQuantity);
      },

      hasMovedNumberOfVolumes() {
        return this.movedNumberOfVolumes !== undefined && this.movedNumberOfVolumes !== null && !isNaN(this.movedNumberOfVolumes);
      }
    }
  }
</script>

<style scoped>
</style>
