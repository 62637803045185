<template>
  <div>
    <div v-if="loadingItem" class="text-center mt-5 pt-5">
      <loading-animation />
    </div>

    <b-row v-else>
      <div class="col mb-3 mt-n1">
        <div class="row mx-md-n3 mx-0">
          <div class="col-xl-2 col-lg-3 col-md-3 col-12">
            <div class="row">
              <div class="col-xl-12 col-sm-auto col-12">
                <small>Reduzido:</small>
              </div>
              <div class="col-xl-12 col-sm col-12">
                <h5>{{ getItemReduzidoFromRef(item.ref) }}</h5>
              </div>
            </div>
          </div>
          <div class="col-xl-10 col-md-9 col-12">
            <div class="row">
              <div class="col-xl-12 col-sm-auto col-12">
                <small>Descrição:</small>
              </div>
              <div class="col-xl-12 col-sm col-12">
                <h5>{{ item.description }}</h5>
              </div>
            </div>
          </div>
          <div class="col-xl col-md col-12">
            <div class="row">
              <div class="col-xl-12 col-sm-auto col-12">
                <small>Código de barras:</small>
              </div>
              <div class="col-xl-12 col-md-auto col-sm-6 col-12">
                <h5>{{ item.gtin }}</h5>
              </div>
            </div>
          </div>
          <div class="col-md-auto col-4">
            <div class="row">
              <div class="col-xl-12 col-sm-auto col-12">
                <small>Total Alocado:</small>
              </div>
              <div class="col-xl-12 col-md-auto col-sm-6 col-12">
                <h5>{{ item.totalAllocated }}</h5>
              </div>
            </div>
          </div>
          <div class="col-md-auto col-4">
            <div class="row">
              <div class="col-xl-12 col-sm-auto col-12">
                <small>Endereços:</small>
              </div>
              <div class="col-xl-12 col-sm col-12">
                <h5>{{ item.totalAllocations }}</h5>
              </div>
            </div>
          </div>
          <div class="col-md-auto col-4">
            <div class="row">
              <div class="col-xl-12 col-sm-auto col-12">
                <small>Capacidade total:</small>
              </div>
              <div class="col-xl-12 col-sm col-12">
                <h5>{{ item.totalCapacity }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-tabs pills card lazy class="col-12">
        <b-tab active>
          <template slot="title">
            <i class="fa fa-cube"> Armazéns</i>
          </template>

          <div v-if="loadingItemWarehouses" class="mb-5 pb-5">
            <loading-animation />
          </div>

          <b-card-body>
            <div class="row mx-md-n3 mx-1 mb-2 align-items-end">
              <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-8 mb-xl-0 mb-3">
                <b-form-group
                  label="Nome"
                  label-for="warehousesSearchText"
                  class="mb-0"
                >
                  <b-form-input
                    id="warehousesSearchText"
                    v-model="warehousesSearchText"
                    @keyup.enter="searchItemWarehouses"
                    placeholder="Procurar por..."
                  />
                </b-form-group>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-4">
                <button @click="searchItemWarehouses" type="button" class="btn btn-primary">Buscar</button>
              </div>
            </div>

            <div v-if="warehouseAppliedFilters.length" class="row mb-2 mx-0">
              <div v-for="(filter, index) in warehouseAppliedFilters" class="col-auto px-1" :key="index">
                <h5>
                  <span class="badge badge-warning">{{filter.label}} = {{filter.value}}</span>
                </h5>
              </div>
            </div>

            <div class="row mx-md-n3 mx-1" v-if="hasWarehouses">
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12" v-for="(warehouse, index) in item.warehouses" :key="index">
                <div class="card">
                  <div class="card-body mx-md-0 mx-3">
                    <div class="h4 mb-0 cursor-pointer"  :id="warehouse.id">
                      {{ warehouse.quantity }}
                    </div>

                    <small class="text-muted font-weight-bold">{{ warehouse.name }}</small>
                  </div>
                </div>
              </div>
            </div>

            <b-row v-else class="justify-content-center pt-3">
              <h6 class="col-auto">Nenhum armazém encontrado para a pesquisa</h6>
            </b-row>
          </b-card-body>
        </b-tab>

        <b-tab @click="tabChangedToLocations">
          <template slot="title">
            <i class="fa fa-cube"> Endereços</i>
          </template>

          <div v-if="loadingLocations" class="mb-5 pb-5">
            <loading-animation />
          </div>

          <b-card-body>
            <div class="row mx-md-n3 mx-1 mb-2 align-items-end">
              <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                <b-form-group
                  label="Nome"
                  label-for="locationsSearchText"
                  class="mb-0"
                >
                  <b-form-input
                    id="locationsSearchText"
                    v-model="locationsSearchText"
                    @keyup.enter="searchItemLocations"
                    placeholder="Procurar por..."
                  />
                </b-form-group>
              </div>
              <div class="col-sm-auto col-6">
                <b-dropdown text="Filtros" class="mobile-btn-100">
                  <b-dropdown-form>
                    <b-form-group
                      label="Armazém"
                    >
                      <div v-if="loadingWarehouses">
                        <loading-animation />
                      </div>
                      <model-list-select
                        :list="warehouses"
                        option-value="id"
                        option-text="name"
                        v-model="locationsSelectedWarehouse"
                        placeholder="Selecione o Armazém"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Lote"
                    >
                      <div v-if="loadingLots">
                        <loading-animation />
                      </div>
                      <model-list-select
                        :list="locationLots"
                        option-value="id"
                        option-text="lotNumber"
                        v-model="locationsSelectedLot"
                        placeholder="Digite para pesquisar"
                        @searchchange="searchLocationLot"
                        :filterPredicate="alwaysTrueFilterPredicate"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Apenas com saldo positivo"
                    >
                      <b-form-checkbox v-model="positiveLocation"></b-form-checkbox>
                    </b-form-group>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6">
                <button @click="searchItemLocations" type="button" class="btn btn-primary mobile-btn-100">Buscar</button>
              </div>
            </div>

            <div v-if="locationAppliedFilters.length" class="row mb-2 mx-0">
              <div v-for="(filter, index) in locationAppliedFilters" class="col-auto px-1" :key="index">
                <h5>
                  <span class="badge badge-warning">{{filter.label}} = {{filter.value}}</span>
                </h5>
              </div>
            </div>

            <div class="row mx-md-n3 mx-1" v-if="hasAllocations">
              <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" v-for="(allocation, index) in item.locations" :key="index">
                <div class="card">
                  <div class="card-body mx-md-0 mx-3">
                    <h6 class="mb-4 text-muted text-right">{{ allocation.stockLocale ? allocation.stockLocale.name : '' }}</h6>
                    <div class="h4 mb-0 cursor-pointer"  :id="allocation.id">
                      {{ allocation.quantity }}
                      <!-- <b-popover
                        placement="right"
                        :target="allocation.uuid">

                        <b-card-body>
                          <div v-for="lot in allocation.lots">
                            <p>Identificador: <strong>{{lot.identifier}}</strong></p>
                            <p>Data: <strong>{{lot.date}}</strong></p>
                            <p>Quantidade: <strong>{{lot.quantity}}</strong></p>
                          </div>
                        </b-card-body>

                      </b-popover> -->
                    </div>

                    <h4 class="font-weight-bold mt-1">{{ allocation.name }}</h4>
                    <div class="text-muted small">{{ allocation.barcode }}</div>
                    <div class="text-muted small">{{ allocation.address }}</div>
                    <div class="text-center mt-3">
                      <small class="text-muted text-uppercase font-weight-bold text-center">
                        {{allocation.quantity}} / {{allocation.quantityMax}}
                      </small>
                    </div>
                    <b-progress height={} class="progress-xs mt-0 mb-0" variant="success"
                                :value="(allocation.quantity / allocation.quantityMax) * 100"/>
                    <small class="text-muted text-uppercase font-weight-bold">Quantidade x Capacidade</small>
                  </div>
                </div>
              </div>
            </div>

            <b-row v-else class="justify-content-center pt-3">
              <h6 class="col-auto">Nenhum endereço encontrado para a pesquisa</h6>
            </b-row>

            <nav>
              <b-pagination
                align="center"
                :limit="10"
                :total-rows="locationsTotalRows"
                :per-page="locationsPerPage"
                prev-text="Anterior"
                next-text="Próximo"
                number-of-page="numberOfPages"
                v-model="locationsCurrentPage"
                @change="locationsPageChanged"
                :disabled="loadingLocations"/>
            </nav>
          </b-card-body>
        </b-tab>

        <b-tab @click="tabChangedToLots">
          <template slot="title">
            <i class="fa fa-barcode"> Lotes</i>
          </template>

          <b-card-body>
            <div class="row mx-md-n3 mx-1 mb-2 align-items-end">
              <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                <b-form-group
                  label="Número do Lote"
                  label-for="lotsSearchText"
                  class="mb-0"
                >
                  <b-form-input
                    id="lotsSearchText"
                    v-model="lotsSearchText"
                    @keyup.enter="lotsSearchButtonPressed"
                    placeholder="Procurar por..."
                  />
                </b-form-group>
              </div>
              <div class="col-sm-auto col-6">
                <b-dropdown text="Filtros" class="mobile-btn-100">
                  <b-dropdown-form>
                    <div class="row">
                      <div class="col-sm-6 col-12">
                        <b-form-group
                          label="Data Inicial"
                          label-for="lotsStartDate"
                        >
                          <b-form-input
                            id="lotsStartDate"
                            v-model="lotsStartDateStr"
                            required
                            type="date"/>
                        </b-form-group>
                      </div>
                      <div class="col-sm-6 col-12">
                        <b-form-group
                          label="Data Final"
                          label-for="lotsEndDate"
                        >
                          <b-form-input
                            id="lotsEndDate"
                            v-model="lotsEndDateStr"
                            required
                            type="date"/>
                        </b-form-group>
                      </div>
                    </div>
                    <b-form-group
                      label="Apenas com estoque"
                    >
                      <b-form-checkbox v-model="onlyPositiveItemLots"></b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label="Armazém"
                    >
                      <div v-if="loadingWarehouses">
                        <loading-animation />
                      </div>
                      <model-list-select
                        :list="warehouses"
                        option-value="id"
                        option-text="name"
                        v-model="lotsSelectedWarehouse"
                        placeholder="Selecione o Armazém"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Endereço"
                    >
                      <div v-if="loadingAllocations">
                        <loading-animation />
                      </div>
                      <model-list-select
                        :list="lotLocations"
                        option-value="id"
                        option-text="name"
                        v-model="lotsSelectedLocation"
                        placeholder="Selecione o Endereço"
                        @searchchange="searchLotAllocation"
                        :filterPredicate="alwaysTrueFilterPredicate"
                      />
                    </b-form-group>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6">
                <button @click="lotsSearchButtonPressed" type="button" class="btn btn-primary mobile-btn-100">Buscar</button>
              </div>
            </div>

            <div v-if="lotAppliedFilters.length" class="row mb-2 mx-0">
              <div v-for="(filter, index) in lotAppliedFilters" class="col-auto px-1" :key="index">
                <h4>
                  <span class="badge badge-warning">{{filter.label}} = {{filter.value}}</span>
                </h4>
              </div>
            </div>

            <div class="row mx-md-n3 mx-1">
              <b-col sm="12">
                <div v-if="loadingItemLots" class="mb-5 pb-5">
                  <loading-animation />
                </div>

                <div v-if="!loadingItemLots && !itemLots.length" class="mb-3">
                  <div class="row bg-light mx-0 py-3">
                    <div class="col text-center">
                      Nenhum lote encontrado para a pesquisa
                    </div>
                  </div>
                </div>

                <div v-if="!loadingItemLots && itemLots.length" class="mb-3">
                  <div v-for="(item, index) in itemLots" class="row py-3 border mx-0" :key="index"
                    :class="{ 'bg-light': index % 2 != 0 }">

                    <div class="col-12">
                      <div class="row">
                        <div class="col-sm-6 col-12">
                          <span class="font-weight-bold">Número: </span>{{item.lotNumber}}
                        </div>
                        <div class="col-sm-6 col-12">
                          <span class="font-weight-bold">Código de barras: </span>{{item.barcode}}
                        </div>
                        <div class="col-sm-6 col-12">
                          <span class="font-weight-bold">Data: </span>{{item.formattedDate}}
                        </div>
                        <div class="col-sm-6 col-12">
                          <span class="font-weight-bold">Vencimento: </span>{{item.formattedDue}}
                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-1">
                      <div class="row align-items-center">

                        <div class="col-sm col-12">
                          <span class="font-weight-bold">Quantidade Atual: </span>
                          <composite-item-lot-quantity
                            :quantity="item.quantity"
                            :itemLot="item"
                          ></composite-item-lot-quantity>
                        </div>

                        <div class="col-sm-auto col-12">
                          <div class="row align-items-end">

                            <div v-if="isUserManager" class="col pr-0">
                              <b-button variant="secondary"
                                @click="openItemLotUpdateModal(index)"
                                class="mobile-btn-100">
                                <i class="fa fa-edit"></i> Editar
                              </b-button>
                            </div>

                            <div class="col-auto pl-0">
                              <div class="row justify-content-end align-items-center">
                                <h4 class="col-auto mb-0 pr-0">
                                  <i :class="'cui-check ' + (item.printed ? 'text-success' : 'text-white')"></i>
                                </h4>
                                <div class="col-auto">
                                  <b-button :variant="item.printed ? 'secondary' : 'primary'"
                                    @click="openBarcodesPrintModal(index)"
                                    :disabled="loadingBarcodePDF"
                                    class="mobile-btn-100">
                                    Gerar PDF
                                  </b-button>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <nav>
                  <b-pagination
                    align="center"
                    :limit="10"
                    :total-rows="lotsTotalRows"
                    :per-page="lotsPerPage"
                    prev-text="Anterior"
                    next-text="Próximo"
                    number-of-page="numberOfPages"
                    v-model="lotsCurrentPage"
                    @change="lotsPageChanged"
                    :disabled="loadingItemLots"/>
                </nav>
              </b-col>
            </div>
          </b-card-body>
        </b-tab>

        <b-tab @click="tabChangedToBalances">
          <template slot="title">
            <i class="fa fa-cubes"> Estoques</i>
          </template>

          <b-card-body>
            <div class="row mx-md-n3 mx-1 mb-2 align-items-end">
              <div class="col-sm-6 col-12 mb-sm-0 mb-3">
                <b-form-group
                  label="Nome ou Número"
                  label-for="balancesSearchText"
                  class="mb-0"
                >
                  <b-form-input
                    id="balancesSearchText"
                    v-model="balancesSearchText"
                    @keyup.enter="balancesSearchButtonPressed"
                    placeholder="Procurar por..."
                  />
                </b-form-group>
              </div>
              <div class="col-sm-auto col-6">
                <b-dropdown text="Filtros" class="mobile-btn-100">
                  <b-dropdown-form>
                    <b-form-group
                      label="Apenas com saldo positivo"
                    >
                      <b-form-checkbox v-model="onlyPositiveBalances"></b-form-checkbox>
                    </b-form-group>
                    <b-form-group
                      label="Armazém"
                    >
                      <div v-if="loadingWarehouses">
                        <loading-animation />
                      </div>
                      <model-list-select
                        :list="warehouses"
                        option-value="id"
                        option-text="name"
                        v-model="balancesSelectedWarehouse"
                        placeholder="Armazém"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Endereço"
                    >
                      <div v-if="loadingAllocations">
                        <loading-animation />
                      </div>
                      <model-list-select
                        :list="balanceLocations"
                        option-value="id"
                        option-text="name"
                        v-model="balancesSelectedLocation"
                        placeholder="Endereço"
                        @searchchange="searchBalanceAllocation"
                        :filterPredicate="alwaysTrueFilterPredicate"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Lote"
                    >
                      <div v-if="loadingLots">
                        <loading-animation />
                      </div>
                      <model-list-select
                        :list="balanceLots"
                        option-value="id"
                        option-text="lotNumber"
                        v-model="balancesSelectedLot"
                        placeholder="Digite para pesquisar"
                        @searchchange="searchBalanceLot"
                        :filterPredicate="alwaysTrueFilterPredicate"
                      />
                    </b-form-group>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-6">
                <button @click="balancesSearchButtonPressed" type="button" class="btn btn-primary mobile-btn-100">Buscar</button>
              </div>
            </div>

            <div v-if="balancesAppliedFilters.length" class="row mb-2 mx-0">
              <div v-for="(filter, index) in balancesAppliedFilters" class="col-auto px-1" :key="index">
                <h5>
                  <span class="badge badge-warning">{{filter.label}} = {{filter.value}}</span>
                </h5>
              </div>
            </div>

            <div class="row mx-md-n3 mx-1">
              <b-col sm="12">
                <div v-if="loadingItemBalances" class="mb-5 pb-5">
                  <loading-animation />
                </div>

                <div v-if="!loadingItemBalances && !itemBalances.length" class="mb-3">
                  <div class="row bg-light mx-0 py-3">
                    <div class="col text-center">
                      Nenhum estoque encontrado para a pesquisa
                    </div>
                  </div>
                </div>

                <div v-if="!loadingItemBalances && itemBalances.length" class="mb-3">
                  <div v-for="(item, index) in itemBalances" class="row py-3 border mx-0" :key="index"
                    :class="{ 'bg-light': index % 2 != 0 }">
                    <div class="col-xl-9 col-lg-8 col-md-8 col-sm-8 col-6">
                      <div class="row">
                        <div class="col-12">
                          <span class="font-weight-bold">Número do Lote: </span>{{item.itemLot ? item.itemLot.lotNumber : ''}}
                        </div>
                        <div class="col-12">
                          <span class="font-weight-bold">Endereço: </span>{{item.location ? item.location.name : ''}}
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                      <div class="row">
                        <div class="col-12">
                          <span class="font-weight-bold">Entradas: </span>{{item.entries}}
                        </div>
                        <div class="col-12">
                          <span class="font-weight-bold">Saídas: </span>{{item.exits}}
                        </div>
                        <div class="col-12">
                          <span class="font-weight-bold">Saldo: </span>{{(item.entries || 0) - (item.exits || 0)}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <nav>
                  <b-pagination
                    align="center"
                    :limit="10"
                    :total-rows="balancesTotalRows"
                    :per-page="balancesPerPage"
                    prev-text="Anterior"
                    next-text="Próximo"
                    number-of-page="numberOfPages"
                    v-model="balancesCurrentPage"
                    @change="balancesPageChanged"
                    :disabled="loadingItemBalances"/>
                </nav>
              </b-col>
            </div>
          </b-card-body>
        </b-tab>

        <b-tab @click="tabChangedToHistory">
          <template slot="title">
            <i class="fa fa-history"> Histórico</i>
          </template>

          <b-card-body>
            <div class="row mx-md-n3 mx-1 mb-2 align-items-end">
              <div class="col-lg-6 col-sm-7 col-12 mb-sm-0 mb-3">
                <div class="row">
                  <div class="col-sm-6 col-12">
                    <b-form-group
                      label="Data Inicial"
                      label-for="movementsStartDate"
                      class="mb-sm-0 mb-3"
                    >
                      <b-form-input
                        id="movementsStartDate"
                        v-model="movementsStartDateStr"
                        required
                        type="date"/>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6 col-12">
                    <b-form-group
                      label="Data Final"
                      label-for="movementsEndDate"
                      class="mb-0"
                    >
                      <b-form-input
                        id="movementsEndDate"
                        v-model="movementsEndDateStr"
                        required
                        type="date"/>
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-sm-auto col-6">
                <b-dropdown text="Filtros" class="mobile-btn-100">
                  <b-dropdown-form>
                    <b-form-group
                      label="Documento"
                      label-for="historySearchText"
                    >
                      <b-form-input
                        id="historySearchText"
                        v-model="historySearchText"
                        @keyup.enter="historySearchButtonPressed"
                        placeholder="Procurar por..."
                      />
                    </b-form-group>
                    <b-form-group
                      label="Endereço"
                    >
                      <div v-if="loadingAllocations">
                        <loading-animation />
                      </div>
                      <model-list-select
                        :list="movementLocations"
                        option-value="id"
                        option-text="name"
                        v-model="movementsSelectedLocation"
                        placeholder="Endereço"
                        @searchchange="searchMovementAllocation"
                        :filterPredicate="alwaysTrueFilterPredicate"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Armazém"
                    >
                      <div v-if="loadingWarehouses">
                        <loading-animation />
                      </div>
                      <model-list-select
                        :list="warehouses"
                        option-value="id"
                        option-text="name"
                        v-model="movementsSelectedWarehouse"
                        placeholder="Armazém"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Lote"
                    >
                      <div v-if="loadingLots">
                        <loading-animation />
                      </div>
                      <model-list-select
                        :list="movementLots"
                        option-value="id"
                        option-text="lotNumber"
                        v-model="movementsSelectedLot"
                        placeholder="Digite para pesquisar"
                        @searchchange="searchMovementLot"
                        :filterPredicate="alwaysTrueFilterPredicate"
                      />
                    </b-form-group>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-6">
                <button @click="movementsSearchButtonPressed" type="button" class="btn btn-primary mobile-btn-100">Buscar</button>
              </div>
            </div>

            <div v-if="movementsAppliedFilters.length" class="row mb-2 mx-0">
              <div v-for="(filter, index) in movementsAppliedFilters" class="col-auto px-1" :key="index">
                <h5>
                  <span class="badge badge-warning">{{filter.label}} = {{filter.value}}</span>
                </h5>
              </div>
            </div>

            <h3 v-if="!hasAnyMovementFilter" class="mx-md-0 mx-3 text-right mb-2 pb-1">Saldo anterior: {{previousBalance}}</h3>

            <div class="row mx-md-n3 mx-1">
              <b-col sm="12">
                <div v-if="loadingItemLotMovements" class="mb-5 pb-5">
                  <loading-animation />
                </div>

                <div v-if="!loadingItemLotMovements && (!item.itemLotMovements || !item.itemLotMovements.length)" class="mb-3">
                  <div class="row bg-light mx-0 py-3">
                    <div class="col text-center">
                      Nenhum movimento encontrado para a pesquisa
                    </div>
                  </div>
                </div>

                <div v-if="!loadingItemLotMovements && item.itemLotMovements && item.itemLotMovements.length" class="mb-3">
                  <div v-for="(item, index) in item.itemLotMovements" class="row py-3 border mx-0" :key="index"
                    :class="{ 'bg-light': index % 2 != 0 }">
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-6 col-12">
                      <div class="row">
                        <div class="col-xl-12 col-md-4 col-12">
                          <span class="font-weight-bold">Data: </span>{{item.formattedDate}}
                        </div>
                        <div class="col-xl-12 col-md-4 col-12">
                          <span class="font-weight-bold">Documento: </span>
                          {{item.orderItem && item.orderItem.order ? item.orderItem.order.number : ''}}
                          <span v-if="item.orderItem && item.orderItem.order && item.orderItem.order.fiscalOperation" class="badge ml-1"
                            :class="operationBadgeClassObject(item)">
                            {{item.orderItem.order.fiscalOperation.showType}}
                          </span>
                        </div>
                        <div class="col-xl-12 col-md-4 col-12">
                          <span class="font-weight-bold">Pessoa: </span>
                          {{item.orderItem && item.orderItem.order && item.orderItem.order.customer && item.orderItem.order.customer.person ? item.orderItem.order.customer.person.name : ''}}
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                      <div class="row">
                        <div class="col-12">
                          <span class="font-weight-bold">Número do Lote: </span>{{item.itemLot ? item.itemLot.lotNumber : ''}}
                        </div>
                        <div class="col-12">
                          <span class="font-weight-bold">Endereço: </span>{{item.location ? item.location.name : ''}}
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-6 col-sm-7 col-12">
                      <div class="row">
                        <div class="col-4">
                          <span class="font-weight-bold">Entradas</span><br>
                          <composite-item-lot-quantity
                            v-if="item.type === 'ENT'"
                            :quantity="item.quantity"
                            :itemLot="item.itemLot"
                          ></composite-item-lot-quantity>
                        </div>
                        <div class="col-4">
                          <span class="font-weight-bold">Saídas</span><br>
                          <composite-item-lot-quantity
                            v-if="item.type === 'SAI'"
                            :quantity="item.quantity"
                            :itemLot="item.itemLot"
                          ></composite-item-lot-quantity>
                        </div>
                        <div v-if="!hasAnyMovementFilter" class="col-4">
                          <span class="font-weight-bold">Saldo</span><br>{{item.balance}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <nav>
                  <b-pagination
                    align="center"
                    :limit="10"
                    :total-rows="movementsTotalRows"
                    :per-page="movementsPerPage"
                    prev-text="Anterior"
                    next-text="Próximo"
                    number-of-page="numberOfPages"
                    v-model="movementsCurrentPage"
                    @change="movementsPageChanged"
                    :disabled="loadingItemLotMovements"/>
                </nav>
              </b-col>
            </div>
          </b-card-body>
        </b-tab>
      </b-tabs>

      <b-modal
        id="barcodes-print-modal"
        centered
        size="md"
        lazy
        title="Imprimir códigos de barra"
        header-bg-variant="info"
        footer-border-variant="info"
        v-model="showBarcodesPrintModal">
        <b-container>
          <div v-if="loadingBarcodePDF">
            <loading-animation />
          </div>

          <b-form-group
            v-else
            label="Modelo de etiqueta"
          >
            <model-list-select
              :list="barcodePrintModels"
              option-value="value"
              option-text="label"
              v-model="barcodePrintModel"
            />
          </b-form-group>
        </b-container>

        <template slot="modal-footer" slot-scope="{ ok, cancel }">
          <b-button variant="secondary" @click="closeBarcodePrintModal">Cancelar</b-button>
          <b-button variant="primary" @click="printBarcodes" :disabled="!barcodePrintModel || !barcodePrintModel.label || loadingBarcodePDF">
            Gerar PDF
          </b-button>
        </template>
      </b-modal>
    </b-row>

    <item-lot-update-modal
      :itemLot="itemLots[itemLotEditIndex]"
      :saved="itemLotSaved"
      ref="itemLotUpdateModal"
    ></item-lot-update-modal>

  </div>
</template>

<script>
  import LineChart from '@/components/charts/line-charts'
  import TableHistoryItem from '@/components/tables/table-history-item'
  import { historyItem, item, httpClient } from '@/service';
  import shared from '@/shared/shared'
  import { LoadingAnimation } from '@/components/loaders'
  import { ModelListSelect } from 'vue-search-select'
  import Axios from 'axios'
  import CompositeItemLotQuantity from '@/components/common/composite-item-lot-quantity';
  import { auth } from "@/service";
  import ItemLotUpdateModal from '@/components/modals/item-lot-update-modal';

  export default {
    name: 'item-show',
    components: {
      TableHistoryItem,
      LineChart,
      LoadingAnimation,
      ModelListSelect,
      CompositeItemLotQuantity,
      ItemLotUpdateModal
    },

    data () {
      return {
        item: {
          name: '',
          itemLotMovements: []
        },
        url: '',
        movement: {
          totalIn: 0,
          totalOut: 0,
          reallocation: 0,
        },
        previousBalance: 0,
        movementsStartDateStr: '',
        movementsEndDateStr: '',
        loadingLocations: false,
        loadingItemLotMovements: false,
        locationSearchTimeout: null,
        lotSearchTimeout: null,
        loadingAllocations: false,
        loadingLots: false,
        loadingWarehouses: false,
        warehouses: [],
        movementsSelectedWarehouse: {},
        movementLocations: [],
        movementsSelectedLocation: {},
        movementLots: [],
        movementsSelectedLot: {},
        movementsCurrentPage: 1,
        movementsPerPage: 10,
        movementsTotalRows: 10,
        loadingItemLots: false,
        itemLots: [],
        lotsCurrentPage: 1,
        lotsPerPage: 10,
        lotsTotalRows: 10,
        lotsStartDateStr: '',
        lotsEndDateStr: '',
        lotsSelectedWarehouse: {},
        lotLocations: [],
        lotsSelectedLocation: {},
        lotsSearchText: null,
        locationsSelectedWarehouse: {},
        locationsSearchText: null,
        locationLots: [],
        locationsSelectedLot: {},
        loadingItemBalances: false,
        itemBalances: [],
        balancesCurrentPage: 1,
        balancesPerPage: 10,
        balancesTotalRows: 10,
        balancesSearchText: null,
        onlyPositiveBalances: false,
        balanceLots: [],
        balancesSelectedLot: {},
        balancesSelectedWarehouse: {},
        balancesSelectedLocation: {},
        balanceLocations: [],
        loadingBarcodePDF: false,
        loadingItemWarehouses: false,
        warehousesSearchText: null,
        historySearchText: null,
        locationsCurrentPage: 1,
        locationsPerPage: 10,
        locationsTotalRows: 10,
        itemLocationsSearched: false,
        itemLotMovementsSearched: false,
        warehousesSearched: false,
        itemLotsSearched: false,
        itemBalancesSearched: false,
        onlyPositiveItemLots: false,
        showBarcodesPrintModal: false,
        barcodePrintModel: null,
        barcodePrintModels: [],
        config: {},
        saving: false,
        loadingItem: false,
        hasAnyMovementFilter: false,
        printItemLotIndex: null,
        itemLotEditIndex: -1,
        isUserManager: false,
        authUser: null,
        positiveLocation: false
      }
    },

    beforeMount: function () {
      let today = new Date();
      let thisMonthMinus3 = new Date();
      thisMonthMinus3.setMonth(thisMonthMinus3.getMonth() - 3);

      this.lotsStartDateStr = thisMonthMinus3.toJSON().substring(0, 10);
      this.lotsEndDateStr = today.toJSON().substring(0, 10);
      this.movementsStartDateStr = thisMonthMinus3.toJSON().substring(0, 10);
      this.movementsEndDateStr = today.toJSON().substring(0, 10);

      this.config = shared.getConfig();
      this.barcodePrintModel = this.config.printConfig.model;
      this.locationsSearchText = shared.getLocalStorageString('items/show/Locations/locationsSearchText', this.locationsSearchText);
      this.locationsSelectedWarehouse = shared.getLocalStorageObj('items/show/Locations/locationsSelectedWarehouse', this.locationsSelectedWarehouse);
      this.locationsSelectedLot = shared.getLocalStorageObj('items/show/Locations/locationsSelectedLot', this.locationsSelectedLot);
      this.positiveLocation = shared.getLocalStorageString('items/show/Locations/positiveLocation', this.positiveLocation);

      this.lotsSearchText = shared.getLocalStorageString('items/show/Lots/lotsSearchText', this.lotsSearchText);
      this.lotsSelectedWarehouse = shared.getLocalStorageObj('items/show/Lots/lotsSelectedWarehouse', this.lotsSelectedWarehouse);
      this.lotsSelectedLocation = shared.getLocalStorageObj('items/show/Lots/lotsSelectedLocation', this.lotsSelectedLocation);
      this.onlyPositiveItemLots = shared.getLocalStorageString('items/show/Lots/onlyPositiveItemLots', this.onlyPositiveItemLots);

      this.balancesSearchText = shared.getLocalStorageString('items/show/Balances/balancesSearchText', this.balancesSearchText);
      this.balancesSelectedWarehouse = shared.getLocalStorageObj('items/show/Balances/balancesSelectedWarehouse', this.balancesSelectedWarehouse);
      this.balancesSelectedLocation = shared.getLocalStorageObj('items/show/Balances/balancesSelectedLocation', this.balancesSelectedLocation);
      this.balancesSelectedLot = shared.getLocalStorageObj('items/show/Balances/balancesSelectedLot', this.balancesSelectedLot);
      this.onlyPositiveBalances = shared.getLocalStorageString('items/show/Balances/onlyPositiveBalances', this.onlyPositiveBalances);

      this.warehousesSearchText = shared.getLocalStorageString('items/show/warehouses/warehousesSearchText', this.warehousesSearchText);

      this.movementsSelectedLot = shared.getLocalStorageObj('items/show/Movements/movementsSelectedLot', this.movementsSelectedLot);
      this.movementsSelectedLocation = shared.getLocalStorageObj('items/show/Movements/movementsSelectedLocation', this.movementsSelectedLocation);
      this.movementsSelectedWarehouse = shared.getLocalStorageObj('items/show/Movements/movementsSelectedWarehouse', this.movementsSelectedWarehouse);
      this.historySearchText = shared.getLocalStorageString('items/show/Movements/historySearchText', this.historySearchText);


    },

    mounted: function () {
      //const { all:{ href: url } } = localStorage.getItem('endpoints/historyItem');
      //this.url = url.replace('{UUID}', this.item.id)
      this.authUser = shared.getLocalStorageObj("auth/user");
      this.findItem();
      this.setIsUserManager();
      this.$nextTick(() => {
        this.getItemLotPrintModels();
      });
    },

    computed: {
      hasAllocations() {
        return !this.loadingLocations && this.item && this.item.locations && this.item.locations.length;
      },

      hasWarehouses() {
        return !this.loadingItemWarehouses && this.item && this.item.warehouses && this.item.warehouses.length;
      },

      warehouseAppliedFilters() {
        let appliedFilters = [];

        if (this.warehousesSearchText && this.warehousesSearchText.length) {
          appliedFilters.push({ label: 'Nome', value: this.warehousesSearchText });
        }

        return appliedFilters;
      },

      locationAppliedFilters() {
        let appliedFilters = [];

        if (this.locationsSearchText && this.locationsSearchText.length) {
          appliedFilters.push({ label: 'Nome', value: this.locationsSearchText });
        }

        if (this.locationsSelectedWarehouse && this.locationsSelectedWarehouse.id) {
          appliedFilters.push({ label: 'Armazém', value: this.locationsSelectedWarehouse.name });
        }

        if (this.locationsSelectedLot && this.locationsSelectedLot.id) {
          appliedFilters.push({ label: 'Lote', value: this.locationsSelectedLot.lotNumber });
        }

        if (this.positiveLocation) {
          appliedFilters.push({ label: 'Apenas com saldo positvo', value: 'Sim' });
        }

        return appliedFilters;
      },

      lotAppliedFilters() {
        let appliedFilters = [];

        if (this.lotsSearchText && this.lotsSearchText.length) {
          appliedFilters.push({ label: 'Número do Lote', value: this.lotsSearchText });
        }

        if (this.lotsStartDateStr && this.lotsStartDateStr.length) {
          appliedFilters.push({ label: 'Data Inicial', value: shared.formatDate(this.lotsStartDateStr + 'T00:00:00', { dateOnly: true }) });
        }

        if (this.lotsEndDateStr && this.lotsEndDateStr.length) {
          appliedFilters.push({ label: 'Data Final', value: shared.formatDate(this.lotsEndDateStr + 'T00:00:00', { dateOnly: true }) });
        }

        if (this.onlyPositiveItemLots) {
          appliedFilters.push({ label: 'Apenas com estoque', value: 'Sim' });
        }

        if (this.lotsSelectedWarehouse && this.lotsSelectedWarehouse.id) {
          appliedFilters.push({ label: 'Armazém', value: this.lotsSelectedWarehouse.name });
        }

        if (this.lotsSelectedLocation && this.lotsSelectedLocation.id) {
          appliedFilters.push({ label: 'Endereço', value: this.lotsSelectedLocation.name });
        }

        return appliedFilters;
      },

      balancesAppliedFilters() {
        let appliedFilters = [];

        if (this.balancesSearchText && this.balancesSearchText.length) {
          appliedFilters.push({ label: 'Nome ou Número', value: this.balancesSearchText });
        }

        if (this.onlyPositiveBalances) {
          appliedFilters.push({ label: 'Apenas com saldo positivo', value: 'Sim' });
        }

        if (this.balancesSelectedWarehouse && this.balancesSelectedWarehouse.id) {
          appliedFilters.push({ label: 'Armazém', value: this.balancesSelectedWarehouse.name });
        }

        if (this.balancesSelectedLocation && this.balancesSelectedLocation.id) {
          appliedFilters.push({ label: 'Endereço', value: this.balancesSelectedLocation.name });
        }

        if (this.balancesSelectedLot && this.balancesSelectedLot.id) {
          appliedFilters.push({ label: 'Lote', value: this.balancesSelectedLot.lotNumber });
        }

        return appliedFilters;
      },

      movementsAppliedFilters() {
        let appliedFilters = [];

        if (this.movementsStartDateStr && this.movementsStartDateStr.length) {
          appliedFilters.push({ label: 'Data Inicial', value: shared.formatDate(this.movementsStartDateStr + 'T00:00:00', { dateOnly: true }) });
        }

        if (this.movementsEndDateStr && this.movementsEndDateStr.length) {
          appliedFilters.push({ label: 'Data Final', value: shared.formatDate(this.movementsEndDateStr + 'T00:00:00', { dateOnly: true }) });
        }

        if (this.historySearchText && this.historySearchText.length) {
          appliedFilters.push({ label: 'Documento', value: this.historySearchText });
        }

        if (this.movementsSelectedLocation && this.movementsSelectedLocation.id) {
          appliedFilters.push({ label: 'Endereço', value: this.movementsSelectedLocation.name });
        }

        if (this.movementsSelectedWarehouse && this.movementsSelectedWarehouse.id) {
          appliedFilters.push({ label: 'Armazém', value: this.movementsSelectedWarehouse.name });
        }

        if (this.movementsSelectedLot && this.movementsSelectedLot.id) {
          appliedFilters.push({ label: 'Lote', value: this.movementsSelectedLot.lotNumber });
        }

        return appliedFilters;
      },
    },

    methods: {
      async getItemLotPrintModels() {
        this.barcodePrintModels = await shared.getItemLotPrintModels(httpClient, { lotOnly: true });
      },

      findItem() {
        this.loadingItem = true;
        httpClient.get(`${process.env.VUE_APP_API_URL}items/${this.$route.params.id}/with-quantity/with-count-and-max`)
        .then(data => {
          this.loadingItem = false;
          this.item = data.data.data;

          if (this.item) {
            this.searchItemWarehouses();
          }
        }).catch(error => {
          this.loadingItem = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar o item");
          }
        });
      },

      searchItemLocations() {
        if (this.loadingLocations) {
          return;
        }

        this.loadingLocations = true;
        let conditions = [{
          logicalOperator: 'OR',
          conditions: [
            {
              joinType: 'LEFT',
              field: 'item.id',
              value: this.item.id
            },
            {
              joinType: 'LEFT',
              field: 'balances.itemLot.item.id',
              value: this.item.id
            }
          ]
        }];

        if (this.locationsSearchText && this.locationsSearchText.length) {
          conditions.push({
            field: 'name',
            conditionalOperator: 'LIKE_START',
            value: this.locationsSearchText
          });
        }
        shared.setLocalStorageString('items/show/Locations/locationsSearchText', this.locationsSearchText);


        if (this.locationsSelectedWarehouse && this.locationsSelectedWarehouse.id) {
          conditions.push({
            field: 'stockLocale.id',
            value: this.locationsSelectedWarehouse.id
          });
        }

        shared.setLocalStorageObj('items/show/Locations/locationsSelectedWarehouse', this.locationsSelectedWarehouse);


        if (this.locationsSelectedLot && this.locationsSelectedLot.id) {
          conditions.push({
            field: 'balances.itemLot.id',
            value: this.locationsSelectedLot.id
          });
        }

        shared.setLocalStorageObj('items/show/Locations/locationsSelectedLot', this.locationsSelectedLot);


        if (this.positiveLocation) {
          conditions.push({
            field: 'balances.entries',
            conditionalOperator: 'GREATER_THAN',
            expressionValue: 'balances.exits'
          });
        }

        shared.setLocalStorageString('items/show/Locations/positiveLocation', this.positiveLocation);

        httpClient
        .post(`${process.env.VUE_APP_API_URL}locations/condition/item/${this.item.id}/with-quantity?page=${this.locationsCurrentPage - 1}&size=${this.locationsPerPage}`, {
          conditions: conditions,
          groupBy: ['id']
        })
        .then(({ data }) => {
          this.loadingLocations = false;
          this.item.locations = data.data.content;
          this.locationsTotalRows = data.data.totalElements;

          if (!this.movementLocations || !this.movementLocations.length) {
            this.movementLocations = JSON.parse(JSON.stringify(data.data.content));

            if (this.movementLocations) {
              this.movementLocations.unshift({id: null, name: 'Todas'});
            }
          }

          if (!this.lotLocations || !this.lotLocations.length) {
            this.lotLocations = JSON.parse(JSON.stringify(data.data.content));
            if (this.lotLocations) {
              this.lotLocations.unshift({id: null, name: 'Todas'});
            }
          }

          if (!this.balanceLocations || !this.balanceLocations.length) {
            this.balanceLocations = JSON.parse(JSON.stringify(data.data.content));
            if (this.balanceLocations) {
              this.balanceLocations.unshift({id: null, name: 'Todas'});
            }
          }

          let item = this.item;
          this.item = {};
          this.item = item;
        })
        .catch((error) => {
          this.loadingLocations = false;
          if (error.message) {
            this.$notify.error(error)
          }

          this.item.locations = []
        });
      },

      searchItemWarehouses() {
        if (this.loadingItemWarehouses) {
          return;
        }

        this.loadingItemWarehouses = true;
        let conditions = [
          {
            logicalOperator: 'OR',
            conditions: [
              {
                joinType: 'LEFT',
                field: 'locations.item.id',
                value: this.item.id
              },
              {
                joinType: 'LEFT',
                field: 'locations.balances.itemLot.item.id',
                value: this.item.id
              }
            ]
          },
          {
            field: 'active',
            value: true
          }
        ];

        if (this.warehousesSearchText && this.warehousesSearchText.length) {
          conditions.push({
            field: 'name',
            conditionalOperator: 'LIKE_START',
            value: this.warehousesSearchText
          });
        }
        shared.setLocalStorageString('items/show/warehouses/warehousesSearchText', this.warehousesSearchText);


        httpClient
        .post(`${process.env.VUE_APP_API_URL}stock-locales/condition/item/${this.item.id}/with-quantity?page=${0}&size=${1000}`, {
          conditions: conditions,
          distinct: true
        })
        .then(({ data }) => {
          this.loadingItemWarehouses = false;
          this.item.warehouses = data.data.content;

          if (!this.warehouses || !this.warehouses.length) {
            this.warehouses = JSON.parse(JSON.stringify(data.data.content));

            if (this.warehouses) {
              this.warehouses.unshift({id: null, name: 'Todos'});
            }
          }

          let item = this.item;
          this.item = {};
          this.item = item;
        })
        .catch((error) => {
          this.loadingItemWarehouses = false;
          if (error.message) {
            this.$notify.error(error)
          }

          this.item.warehouses = []
        });
      },

      getItemLotMovements() {
        if (this.loadingItemLotMovements) {
          return;
        }

        this.hasAnyMovementFilter = (this.historySearchText && this.historySearchText.length)
          || (this.movementsSelectedLocation && this.movementsSelectedLocation.id)
          || (this.movementsSelectedWarehouse && this.movementsSelectedWarehouse.id)
          || (this.movementsSelectedLot && this.movementsSelectedLot.id);

        this.loadingItemLotMovements = true;

        let additionalConditions = [];

        if (this.historySearchText && this.historySearchText.length) {
          additionalConditions.push({
            field: 'orderItem.order.number',
            value: this.historySearchText
          });
        }

        shared.setLocalStorageString('items/show/Movements/historySearchText', this.historySearchText);

        if (this.movementsSelectedWarehouse && this.movementsSelectedWarehouse.id) {
          additionalConditions.push({
            field: 'location.stockLocale.id',
            value: this.movementsSelectedWarehouse.id
          });
        }

        shared.setLocalStorageObj('items/show/Movements/movementsSelectedWarehouse', this.movementsSelectedWarehouse);

        if (this.movementsSelectedLocation && this.movementsSelectedLocation.id) {
          additionalConditions.push({
            field: 'location.id',
            value: this.movementsSelectedLocation.id
          });
        }

        shared.setLocalStorageObj('items/show/Movements/movementsSelectedLocation', this.movementsSelectedLocation);

        if (this.movementsSelectedLot && this.movementsSelectedLot.id) {
          additionalConditions.push({
            field: 'itemLot.id',
            value: this.movementsSelectedLot.id
          });
        }

        shared.setLocalStorageObj('items/show/Movements/movementsSelectedLot', this.movementsSelectedLot);

        shared.setLocalStorageString('items/show/Movements/movementsStartDateStr', this.movementsStartDateStr);

        shared.setLocalStorageString('items/show/Movements/movementsEndDateStr', this.movementsEndDateStr);


        httpClient
        .post(`${process.env.VUE_APP_API_URL}item-lot-movements/item/${this.item.id}?page=${this.movementsCurrentPage - 1}&size=${this.movementsPerPage}&sort=createdAt,asc`, {
          startDate: this.movementsStartDateStr + 'T00:00:00.000Z',
          endDate: this.movementsEndDateStr + 'T23:59:59.999Z',
          additionalConditions: additionalConditions
        })
        .then(({ data }) => {
          this.loadingItemLotMovements = false;
          this.item.itemLotMovements = data.data.content;
          this.movementsTotalRows = data.data.totalElements;

          if (this.item.itemLotMovements) {
            this.item.itemLotMovements.forEach(itemLotMovement => {
              itemLotMovement.formattedDate = shared.formatDate(itemLotMovement.createdAt);

              if (itemLotMovement.orderItem && itemLotMovement.orderItem.order && itemLotMovement.orderItem.order.fiscalOperation) {
                if (itemLotMovement.orderItem.order.transfer) {
                  itemLotMovement.orderItem.order.fiscalOperation.showType = 'TRANSFERENCIA';
                } if (itemLotMovement.orderItem.order.transformationMS) {
                  itemLotMovement.orderItem.order.fiscalOperation.showType = 'TRANSFORMACAO';
                } if (itemLotMovement.orderItem.order.productionMS) {
                  itemLotMovement.orderItem.order.fiscalOperation.showType = 'PRODUCAO';
                } else if (itemLotMovement.orderItem.order.stockAdjustment) {
                  itemLotMovement.orderItem.order.fiscalOperation.showType = 'AJU';
                } else if (itemLotMovement.orderItem.order.fiscalOperation) {
                  itemLotMovement.orderItem.order.fiscalOperation.showType = itemLotMovement.orderItem.order.fiscalOperation.type;
                }
              }
            })
          }

          let item = this.item;
          this.item = {};
          this.item = item;

          if (data.meta) {
            this.previousBalance = data.meta.previousBalance;
          }

          if (!this.previousBalance) {
            this.previousBalance = 0;
          }
        })
        .catch((error) => {
          this.loadingItemLotMovements = false;
          if (error.message) {
            this.$notify.error(error)
          }

          this.item.itemLotMovements = []
        });
      },

      getWarehouses() {
        if (this.loadingWarehouses) {
          return;
        }

        this.loadingWarehouses = true;
        httpClient
        .post(`${process.env.VUE_APP_API_URL}stock-locales/condition?page=${0}&size=${100}`, {
          conditions: [
            {
              logicalOperator: 'OR',
              conditions: [
                {
                  joinType: 'LEFT',
                  field: 'locations.item.id',
                  value: this.item.id
                },
                {
                  joinType: 'LEFT',
                  field: 'locations.balances.itemLot.item.id',
                  value: this.item.id
                }
              ]
            },
            {
              field: 'active',
              value: true
            }
          ],
          groupBy: [
            'id'
          ]
        })
        .then(({ data }) => {
          this.loadingWarehouses = false;
          this.warehouses = data.data.content;

          if (this.warehouses) {
            this.warehouses.unshift({id: null, name: 'Todos'});
          }
        })
        .catch((error) => {
          this.loadingWarehouses = false;
          if (error.message) {
            this.$notify.error(error)
          } else {
            this.$notify.textError("Houve um erro ao buscar os armazéns");
          }
        });
      },

      searchMovementAllocation (searchText) {
        if (!this.loadingAllocations && (
          (searchText && searchText.length) || !this.movementsSelectedLocation || !this.lotsSelectedLocation.id
        )) {
          if (this.locationSearchTimeout) {
            clearTimeout(this.locationSearchTimeout);
          }

          this.locationSearchTimeout = setTimeout(() => {
            this.loadingAllocations = true

            let conditions = [{
              logicalOperator: 'OR',
              conditions: [
                {
                  joinType: 'LEFT',
                  field: 'item.id',
                  value: this.item.id
                },
                {
                  joinType: 'LEFT',
                  field: 'balances.itemLot.item.id',
                  value: this.item.id
                }
              ]
            }];

            if (this.movementsSelectedWarehouse && this.movementsSelectedWarehouse.id) {
              conditions.push({
                field: 'stockLocale.id',
                value: this.movementsSelectedWarehouse.id
              });
            }

            if (searchText && searchText.length) {
              conditions.push({
                field: 'name',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}locations/condition?page=${0}&size=${10}`, {
              conditions: conditions,
              distinct: true
            })
            .then(({ data }) => {
              this.movementLocations = data.data.content;

              if (this.movementLocations) {
                this.movementLocations.unshift({id: null, name: 'Todas'});
              }

              this.loadingAllocations = false;
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.movementLocations = [];
              this.loadingAllocations = false
            });
          }, 500);
        }
      },

      searchLotAllocation (searchText) {
        if (!this.loadingAllocations && (
          (searchText && searchText.length) || !this.lotsSelectedLocation || !this.lotsSelectedLocation.id
        )) {
          if (this.locationSearchTimeout) {
            clearTimeout(this.locationSearchTimeout);
          }

          this.locationSearchTimeout = setTimeout(() => {
            this.loadingAllocations = true

            let conditions = [{
              logicalOperator: 'OR',
              conditions: [
                {
                  joinType: 'LEFT',
                  field: 'item.id',
                  value: this.item.id
                },
                {
                  joinType: 'LEFT',
                  field: 'balances.itemLot.item.id',
                  value: this.item.id
                }
              ]
            }];

            if (this.lotsSelectedWarehouse && this.lotsSelectedWarehouse.id) {
              conditions.push({
                field: 'stockLocale.id',
                value: this.lotsSelectedWarehouse.id
              });
            }

            if (searchText && searchText.length) {
              conditions.push({
                field: 'name',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}locations/condition?page=${0}&size=${10}`, {
              conditions: conditions,
              distinct: true
            })
            .then(({ data }) => {
              this.lotLocations = data.data.content;

              if (this.lotLocations) {
                this.lotLocations.unshift({id: null, name: 'Todas'});
              }

              this.loadingAllocations = false;
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.lotLocations = [];
              this.loadingAllocations = false
            });
          }, 500);
        }
      },

      searchBalanceAllocation (searchText) {
        if (!this.loadingAllocations && (
          (searchText && searchText.length) || !this.balancesSelectedLocation || !this.balancesSelectedLocation.id
        )) {
          if (this.locationSearchTimeout) {
            clearTimeout(this.locationSearchTimeout);
          }

          this.locationSearchTimeout = setTimeout(() => {
            this.loadingAllocations = true

            let conditions = [{
              logicalOperator: 'OR',
              conditions: [
                {
                  joinType: 'LEFT',
                  field: 'item.id',
                  value: this.item.id
                },
                {
                  joinType: 'LEFT',
                  field: 'balances.itemLot.item.id',
                  value: this.item.id
                }
              ]
            }];

            if (this.balancesSelectedWarehouse && this.balancesSelectedWarehouse.id) {
              conditions.push({
                field: 'stockLocale.id',
                value: this.balancesSelectedWarehouse.id
              });
            }

            if (searchText && searchText.length) {
              conditions.push({
                field: 'name',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}locations/condition?page=${0}&size=${10}`, {
              conditions: conditions,
              distinct: true
            })
            .then(({ data }) => {
              this.balanceLocations = data.data.content;

              if (this.balanceLocations) {
                this.balanceLocations.unshift({id: null, name: 'Todas'});
              }

              this.loadingAllocations = false;
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.balanceLocations = [];
              this.loadingAllocations = false
            });
          }, 500);
        }
      },

      searchMovementLot (searchText) {
        if (!this.loadingLots && (
          (searchText && searchText.length) || !this.movementsSelectedLot || Object.keys(this.movementsSelectedLot).length == 0
        )) {
          if (this.lotSearchTimeout) {
            clearTimeout(this.lotSearchTimeout);
          }

          this.lotSearchTimeout = setTimeout(() => {
            this.loadingLots = true

            let conditions = [{
              field: 'item.id',
              conditionalOperator: 'EQUALS',
              value: this.item.id
            }];

            if (searchText && searchText.length) {
              conditions.push({
                field: 'lotNumber',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}item-lots/condition?page=${0}&size=${10}&sort=createdAt,asc`, {
              conditions: conditions
            })
            .then(({ data }) => {
              this.movementLots = data.data.content

              if (this.movementLots) {
                this.movementLots.unshift({id: null, lotNumber: 'Todos'});
              }

              this.loadingLots = false
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.movementLots = [];
              this.loadingLots = false
            });
          }, 500);
        }
      },

      searchBalanceLot (searchText) {
        if (!this.loadingLots && (
          (searchText && searchText.length) || !this.balancesSelectedLot || Object.keys(this.balancesSelectedLot).length == 0
        )) {
          if (this.lotSearchTimeout) {
            clearTimeout(this.lotSearchTimeout);
          }

          this.lotSearchTimeout = setTimeout(() => {
            this.loadingLots = true

            let conditions = [{
              field: 'item.id',
              conditionalOperator: 'EQUALS',
              value: this.item.id
            }];

            if (searchText && searchText.length) {
              conditions.push({
                field: 'lotNumber',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}item-lots/condition?page=${0}&size=${10}&sort=createdAt,asc`, {
              conditions: conditions
            })
            .then(({ data }) => {
              this.balanceLots = data.data.content

              if (this.balanceLots) {
                this.balanceLots.unshift({id: null, lotNumber: 'Todos'});
              }

              this.loadingLots = false
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.balanceLots = [];
              this.loadingLots = false
            });
          }, 500);
        }
      },

      movementsPageChanged(page) {
        this.movementsCurrentPage = page;
        this.getItemLotMovements();
      },

      searchItemLots() {
        if (!this.loadingItemLots) {
          this.loadingItemLots = true

          let additionalConditions = [];

          if (this.lotsSearchText && this.lotsSearchText.length) {
            additionalConditions.push({
              field: 'lotNumber',
              conditionalOperator: 'LIKE_START',
              value: this.lotsSearchText
            });
          }

          shared.setLocalStorageString('items/show/Lots/lotsSearchText', this.lotsSearchText);

          if (this.lotsSelectedWarehouse && this.lotsSelectedWarehouse.id) {
            additionalConditions.push({
              field: 'balances.location.stockLocale.id',
              value: this.lotsSelectedWarehouse.id
            });
          }

          shared.setLocalStorageObj('items/show/Lots/lotsSelectedWarehouse', this.lotsSelectedWarehouse);

          if (this.lotsSelectedLocation && this.lotsSelectedLocation.id) {
            additionalConditions.push({
              field: 'balances.location.id',
              value: this.lotsSelectedLocation.id
            });
          }

          shared.setLocalStorageObj('items/show/Lots/lotsSelectedLocation', this.lotsSelectedLocation);

          if (this.onlyPositiveItemLots) {
            additionalConditions.push({
              field: 'balances.entries',
              conditionalOperator: 'GREATER_THAN',
              expressionValue: 'balances.exits'
            });
          }

          shared.setLocalStorageString('items/show/Lots/onlyPositiveItemLots', this.onlyPositiveItemLots);


          httpClient
          .post(`${process.env.VUE_APP_API_URL}item-lots/item/${this.item.id}?page=${this.lotsCurrentPage - 1}&size=${this.lotsPerPage}&sort=createdAt,desc&sort=lotNumber,asc`, {
            startDate: this.lotsStartDateStr + 'T00:00:00.000Z',
            endDate: this.lotsEndDateStr + 'T23:59:59.999Z',
            additionalConditions: additionalConditions
          })
          .then(({ data }) => {

            this.itemLots = data.data.content;
            this.lotsTotalRows = data.data.totalElements;

            if (!this.movementLots || !this.movementLots.length) {
              this.movementLots = JSON.parse(JSON.stringify(data.data.content));

              if (this.movementLots) {
                this.movementLots.unshift({id: null, lotNumber: 'Todos'});
              }
            }

            if (!this.locationLots || !this.locationLots.length) {
              this.locationLots = JSON.parse(JSON.stringify(data.data.content));
              if (this.locationLots) {
                this.locationLots.unshift({id: null, lotNumber: 'Todos'});
              }
            }

            if (!this.balanceLots || !this.balanceLots.length) {
              this.balanceLots = JSON.parse(JSON.stringify(data.data.content));
              if (this.balanceLots) {
                this.balanceLots.unshift({id: null, lotNumber: 'Todos'});
              }
            }

            if (this.itemLots) {
              this.itemLots.forEach(itemLot => {
                if (itemLot.createdAt) {
                  itemLot.formattedDate = shared.formatDate(itemLot.createdAt);
                }

                if (itemLot.due) {
                  itemLot.formattedDue = shared.formatDate(itemLot.due, { dateOnly: true });
                }

                itemLot.printQuantity = 0;
              });
            }

            this.loadingItemLots = false
          })
          .catch((error) => {
            if (error.message) {
              this.$notify.error(error)
            }
            this.itemLots = [];
            this.loadingItemLots = false
          });
        }
      },

      lotsPageChanged(page) {
        this.lotsCurrentPage = page;
        this.searchItemLots();
      },

      searchLocationLot (searchText) {
        if (!this.loadingLots && (
          (searchText && searchText.length) || !this.locationsSelectedLot || Object.keys(this.locationsSelectedLot).length == 0
        )) {
          if (this.lotSearchTimeout) {
            clearTimeout(this.lotSearchTimeout);
          }

          this.lotSearchTimeout = setTimeout(() => {
            this.loadingLots = true

            let conditions = [{
              field: 'item.id',
              conditionalOperator: 'EQUALS',
              value: this.item.id
            }];

            if (searchText && searchText.length) {
              conditions.push({
                field: 'lotNumber',
                conditionalOperator: 'LIKE_START',
                value: searchText
              });
            }

            httpClient
            .post(`${process.env.VUE_APP_API_URL}item-lots/condition?page=${0}&size=${10}&sort=createdAt,asc`, {
              conditions: conditions
            })
            .then(({ data }) => {
              this.locationLots = data.data.content

              if (this.locationLots) {
                this.locationLots.unshift({id: null, lotNumber: 'Todos'});
              }

              this.loadingLots = false
            })
            .catch((error) => {
              if (error.message) {
                this.$notify.error(error)
              }
              this.locationLots = [];
              this.loadingLots = false
            });
          }, 500);
        }
      },

      lotsSearchButtonPressed() {
        this.lotsCurrentPage = 1;
        this.searchItemLots();
      },

      movementsSearchButtonPressed() {
        this.movementsCurrentPage = 1;
        this.getItemLotMovements();
      },

      balancesSearchButtonPressed() {
        this.balancesCurrentPage = 1;
        this.searchItemBalances();
      },

      balancesPageChanged(page) {
        this.balancesCurrentPage = page;
        this.searchItemBalances();
      },

      searchItemBalances() {
        if (!this.loadingItemBalances) {
          this.loadingItemBalances = true

          let conditions = [{
            field: 'itemLot.item.id',
            value: this.item.id
          }];

          if (this.balancesSearchText && this.balancesSearchText.length) {
            conditions.push({
              logicalOperator: 'OR',
              conditions: [
                {
                  field: 'location.name',
                  conditionalOperator: 'LIKE_START',
                  value: this.balancesSearchText
                },
                {
                  field: 'itemLot.lotNumber',
                  conditionalOperator: 'LIKE_START',
                  value: this.balancesSearchText
                }
              ]
            });
          }

          shared.setLocalStorageString('items/show/Balances/balancesSearchText', this.balancesSearchText);

          if (this.balancesSelectedWarehouse && this.balancesSelectedWarehouse.id) {
            conditions.push({
              field: 'location.stockLocale.id',
              value: this.balancesSelectedWarehouse.id
            });
          }

          shared.setLocalStorageObj('items/show/Balances/balancesSelectedWarehouse', this.balancesSelectedWarehouse);

          if (this.balancesSelectedLocation && this.balancesSelectedLocation.id) {
            conditions.push({
              field: 'location.id',
              value: this.balancesSelectedLocation.id
            });
          }

          shared.setLocalStorageObj('items/show/Balances/balancesSelectedLocation', this.balancesSelectedLocation);

          if (this.balancesSelectedLot && this.balancesSelectedLot.id) {
            conditions.push({
              field: 'itemLot.id',
              value: this.balancesSelectedLot.id
            });
          }

          shared.setLocalStorageObj('items/show/Balances/balancesSelectedLot', this.balancesSelectedLot);

          if (this.onlyPositiveBalances) {
            conditions.push({
              field: 'entries',
              conditionalOperator: 'GREATER_THAN',
              expressionValue: 'exits'
            });
          }

          shared.setLocalStorageString('items/show/Balances/onlyPositiveBalances', this.onlyPositiveBalances);

          httpClient
          .post(`${process.env.VUE_APP_API_URL}balances/condition?page=${this.balancesCurrentPage - 1}&size=${this.balancesPerPage}`, {
            conditions: conditions
          })
          .then(({ data }) => {
            this.itemBalances = data.data.content;
            this.balancesTotalRows = data.data.totalElements;

            this.loadingItemBalances = false
          })
          .catch((error) => {
            if (error.message) {
              this.$notify.error(error)
            }
            this.itemBalances = [];
            this.loadingItemBalances = false
          });
        }
      },

      printBarcodes() {
        let itemLot = this.itemLots[this.printItemLotIndex];
        itemLot.printBarcode = true;
        itemLot.printQuantity = 1;
        let barcodeModels = shared.getBarcodeModelsFromItemLots([itemLot], this.barcodePrintModel);

        if (barcodeModels.length) {
          this.config = shared.setModelAndTemplateIdFromSelectedBarcodeModel(this.config, this.barcodePrintModel);

          this.loadingBarcodePDF = true;
          Axios({
            url: process.env.VUE_APP_API_URL + `item-lots/barcode/pdf`,
            method: 'POST',
            data:  {
              models: barcodeModels,
              options: this.config.printConfig
            },
            responseType: 'blob',
            headers: auth.getAxiosHeaders()
          }).then((response) => {
            this.loadingBarcodePDF = false;
            if (response && response.data && response.data.size) {
              let file = new Blob([response.data], { type: 'application/pdf' });
              let url = window.URL.createObjectURL(file);
              window.open(url);

              this.itemLots[this.printItemLotIndex].printed = true;
              this.closeBarcodePrintModal();
              this.$bvModal.hide('barcodes-print-modal');
            } else {
              this.$notify.textError('Houve um erro ao gerar o PDF. Por favor tente novamente');
            }
          }).catch((error) => {
            this.loadingBarcodePDF = false;

            if (error.response && error.response.headers && error.response.headers.errormessage) {
              this.$notify.textError(error.response.headers.errormessage);
            } else if (error.message) {
              this.$notify.error(error)
            } else {
              this.$notify.textError('Houve um erro ao gerar o PDF. Por favor tente novamente');
            }
          });
        } else {
          this.$notify.textError('Nenhuma impressão selecionada');
        }
      },

      operationBadgeClassObject(ilm) {
        if (ilm.orderItem && ilm.orderItem.order) {
          return shared.operationBadgeClassObject(ilm.orderItem.order);
        } else {
          return '';
        }
      },

      getItemReduzidoFromRef(ref) {
        return shared.getItemReduzidoFromRef(ref);
      },

      alwaysTrueFilterPredicate() {
        return true;
      },

      historySearchButtonPressed() {
        this.movementsCurrentPage = 1;
        this.getItemLotMovements();
      },

      locationsPageChanged(page) {
        this.locationsCurrentPage = page;
        this.searchItemLocations();
      },

      tabChangedToLocations() {
        this.tabChanged();

        if (!this.itemLocationsSearched) {
          this.searchItemLocations();
          this.itemLocationsSearched = true;
        }
      },

      tabChangedToLots() {
        this.tabChanged();

        if (!this.itemLotsSearched) {
          this.searchItemLots();
          this.itemLotsSearched = true;
        }
      },

      tabChangedToBalances() {
        this.tabChanged();

        if (!this.itemBalancesSearched) {
          this.searchItemBalances();
          this.itemBalancesSearched = true;
        }
      },

      tabChangedToHistory() {
        this.tabChanged();

        if (!this.itemLotMovementsSearched) {
          this.getItemLotMovements();
          this.itemLotMovementsSearched = true;
        }
      },

      tabChanged() {
        if (!this.warehousesSearched) {
          this.getWarehouses();
          this.warehousesSearched = true;
        }
      },

      openBarcodesPrintModal(index) {
        this.showBarcodesPrintModal = true;
        this.printItemLotIndex = index;
      },

      closeBarcodePrintModal() {
        this.showBarcodesPrintModal = false;
      },

      openItemLotUpdateModal(index) {
        this.itemLotEditIndex = index;

        this.$nextTick(() => {
          this.$refs.itemLotUpdateModal.showModal();
        });
      },

      itemLotSaved(itemLot) {
        this.itemLots[this.itemLotEditIndex] = Object.assign(this.itemLots[this.itemLotEditIndex], itemLot)
      },

      setIsUserManager() {
        if (
          this.authUser &&
          this.authUser.profile &&
          this.authUser.profile.modules
        ) {
          let itemsProfileModule = this.authUser.profile.modules.find(
            (pm) => {
              return (
                pm.name && pm.name.toString().toUpperCase() == "ITEMS"
              );
            }
          );

          this.isUserManager =
            itemsProfileModule && itemsProfileModule.canWrite;
        }
      },
    },

    watch: {
      'movementsSelectedWarehouse': function(newVal, oldVal) {
        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.searchMovementAllocation();
        }
      },
      'lotsSelectedWarehouse': function(newVal, oldVal) {
        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.searchLotAllocation();
        }
      },
      'balancesSelectedWarehouse': function(newVal, oldVal) {
        if (!shared.entitiesAreEqual(newVal, oldVal)) {
          this.searchBalanceAllocation();
        }
      }
    }
  }
</script>

<style scoped>
  .information-item {
    padding: 0.4rem;
    margin-bottom: 1em;
    background-color: rgba(220, 220, 220, 0.2);
    border-top: 3px solid #1fb7cc;
    border-top-left-radius: .20rem;
    border-top-right-radius: .20rem;
    box-shadow: 0px 3px 5px 0 rgba(0,0,0,0.3);
  }

  .dropdown-menu form {
    width: 410px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media (max-width: 575px) {
    .dropdown-menu form {
      width: 290px;
    }
  }
</style>
